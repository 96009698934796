import React from "react";
// material ui
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: '#1e2334',//theme.palette.background.paper,
    padding: theme.spacing(8, 0, 1),
    "color": '#FFF',
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
   <div className={classes.heroContent}>
     <Container maxWidth="sm">
       <Typography component="h1" variant="h3" align="center" gutterBottom style={{ fontFamily: 'Girloub', color: '#9AF852' }}>
         NFTs Your Work Now!
       </Typography>
                <Typography variant="body1" align="center" color="#7A849D" paragraph>
         Upload your work to a&nbsp;NFT market without a&nbsp;commission
       </Typography>
         <Typography variant="body1" align="center" color="textSecondary" paragraph>
         <Link
            to={{ pathname: "https://www.notion.so/Why-NFT-FAQ-94ea9d5a901f4cd78b9018056183cf4b" }}
            target="_blank"
            style={{ color: '#7a839d', textDecoration: 'none' }}
            >
            Why NFT (FAQ)?
          </Link>
       </Typography>
     </Container>
   </div>
  );
};

export default Header;
