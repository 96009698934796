import React from "react";

// material ui
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{color: '#7a839d'}}>
      {'Copyright © '}
      <Link color="inherit" href="https://github.com/whynft">
        why-nft-team
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#1e2334', //theme.palette.background.paper,
    "color": '#9AF852',
    padding: theme.spacing(6),
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
   <footer className={classes.footer}>
     <Typography variant="h2" align="center" style={{ fontFamily: 'Girloub',whiteSpace: 'nowrap' }} gutterBottom>
       Why NFT?
     </Typography>
     <Typography variant="subtitle1" align="center" color="textSecondary" component="p" style={{color: '#7a839d'}}>
       <Link
        href="mailto:kadochnikova@bk.ru?subject=Вопрос по why-nft.com"
        color="inherit"
        >
         kadochnikova@bk.ru
       </Link>
     </Typography>
     <Copyright />
   </footer>

  );
};

export default Footer;
