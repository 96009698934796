import React, { useState } from "react";
import ApiServices from "../../../services/ApiServices";
// import components
import HinzuButton from '../../../components/HinzuButton';
import ResultView from '../../ResultView/ResultView';
// material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import AccountCircle from '@material-ui/icons/AccountCircle';  // todo: color
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TitleIcon from '@material-ui/icons/Title';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import {FormControl, InputAdornment, InputLabel, OutlinedInput, TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: '#1e2334',//theme.palette.background.paper,
    padding: theme.spacing(0, 0, 6),
    "color": '#7A849D',
  },
  underCardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardImgOnly: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    }
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  mediaSkelet: {
    height: 390,
  },
    margin: {
    marginBottom: theme.spacing(1),
  },
}));


const InputLabelColored = withStyles({
  root: {
    color: '#7a839d',
  },
})(InputLabel);

const OutlinedInputColored = withStyles({
  root: {
    borderColor: '#FFF',
  },
})(OutlinedInput);

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#7a839d"
    },
    "&:hover $notchedOutline": {
      borderColor: "#9AF852"
    },
    "&$focused $notchedOutline": {
      borderColor: "#9AF852"
    }
  },
  focused: {},
  notchedOutline: {}
}));


const ImageUploadAction = () => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [FormValues, setFormValues] = React.useState({
    user_address: '',
    title: '',
    email: '',
  });
  const handleChange = (prop) => (event) => {
    setFormValues({ ...FormValues, [prop]: event.target.value });
  };
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [ifmessage, setIfmessage] = useState(false);

  const [uploadStart, setUploadStart] = useState(false);
  const [resultUrl, setResultUrl] = useState('');

  const selectFile = (event) => {
  setIfmessage(false);
  setUploadStart(false);
  setResultUrl('');
  setSelectedFiles(event.target.files);

  let reader = new FileReader();
  let file = event.target.files[0];

  reader.onloadend = () => {
    setImagePreviewUrl(reader.result);
  }
  reader.readAsDataURL(file)
  };

  const upload = () => {
    setImagePreviewUrl(false);
    setUploadStart(true);
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    ApiServices.upload(
        currentFile,
        FormValues,
        (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        },
        )
      .then((response) => {
        setMessage("Yeah! Success");
        setIfmessage(true);
        setMessageType('success');
        setResultUrl(response.data.rarible_link);
        // return ApiServices.getFiles();
      })
      // .then((files) => {
      //   setFileInfos(files.data);
      //   setMessage("Uploaded successfully");
      // })
      .catch((error) => {
        console.log(error.response);
        setProgress(0);
        setCurrentFile(undefined);
        setImagePreviewUrl(true);
        setIfmessage(true);

        if (error.response.status === 400) {
          setMessageType('warning');
          setMessage("Uf, seems the submitted data is not correct, try again with another?");
        } else {
          setMessageType('error');
          setMessage("Oops, something goes wrong on a server. May be try latter ;(");
        }

      });

    setSelectedFiles(undefined);
  };

  const cancel = () => {
    setSelectedFiles(undefined);
    setImagePreviewUrl('');
    setUploadStart(false);
    setIfmessage(false);
    setFormValues({ ...FormValues, ['title']: '' });
  };

  // if (progress === 100) {return <Redirect to="/result" />}

  let $imagePreview = null;
   if (imagePreviewUrl) {
     $imagePreview = (
   <Grid container spacing={2} justify="center">
     <Grid xs={12} sm={6} md={6} item>
       <Card className={classes.card}>
         <CardMedia
           className={classes.cardImgOnly}
           >
           <img
             src={imagePreviewUrl}
             style={{width: '100%'}}
           />
         </CardMedia>
       </Card>
     </Grid>

     <Grid container spacing={2} justify="center" className={classes.underCardGrid}>
       {selectedFiles ? (
         <Grid item>
           <HinzuButton
             variant="contained"
             color="primary"
             onClick={upload}
             >
             Prepare for Selling
           </HinzuButton>
         </Grid>
       ) : (<div></div>)}
       <Grid item>
         <HinzuButton
           variant="contained"
           color="primary"
           onClick={cancel}
           >
           Select Another
         </HinzuButton>
       </Grid>
    </Grid>
   </Grid>
     );
   } else {
     $imagePreview = (<div></div>);
   }

  return (
<div>
   <div className={classes.heroContent}>
     <Container maxWidth="sm">
       <Collapse in={ifmessage}>
          <Alert
            severity={messageType}
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage("");
                  setIfmessage(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Collapse>

        <br/>

       {
         }

        {uploadStart ? ((resultUrl) ? (
          <ResultView url={resultUrl}/>
        ) : (
          <Grid container spacing={2} justify="center">
            <Grid xs={12} sm={7} md={7} item>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardImgOnly}
                  >
                 <Skeleton animation="wave" variant="rect" className={classes.mediaSkelet} />
               </CardMedia>
               <CardContent>

                <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={10} width="100%" />
               </CardContent>
             </Card>
           </Grid>
           <Grid container spacing={2} className={classes.underCardGrid}>
           </Grid>
         </Grid>
        )) : (
            !(resultUrl) ? (
             <Grid container justify="center">
             <Grid xs={12} sm={7} md={7} item>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabelColored htmlFor="outlined-adornment-amount">ETH Wallet Address</InputLabelColored>
                  <OutlinedInput
                    style={{ color: "#9AF852" }}
                    id="outlined-adornment-amount"
                    value={FormValues.user_address}
                    onChange={handleChange('user_address')}
                    startAdornment={<InputAdornment position="start"><AccountCircle style={{ color: '#9AF852' }}/></InputAdornment>}
                    labelWidth={140}
                    classes={outlinedInputClasses}
                  />
                </FormControl>
               <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabelColored htmlFor="outlined-adornment-amount">Email</InputLabelColored>
                  <OutlinedInput
                    style={{ color: "#9AF852" }}
                    id="outlined-adornment-amount"
                    value={FormValues.email}
                    onChange={handleChange('email')}
                    startAdornment={<InputAdornment position="start"><AlternateEmailIcon style={{ color: '#9AF852' }}/></InputAdornment>}
                    labelWidth={40}
                    classes={outlinedInputClasses}
                  />
                </FormControl>
               <FormControl fullWidth className={classes.margin} variant="outlined">
                  <InputLabelColored htmlFor="outlined-adornment-amount">Title</InputLabelColored>
                  <OutlinedInput
                    style={{ color: "#9AF852" }}
                    id="outlined-adornment-amount"
                    value={FormValues.title}
                    onChange={handleChange('title')}
                    startAdornment={<InputAdornment position="start"><TitleIcon style={{ color: '#9AF852' }}/></InputAdornment>}
                    labelWidth={40}
                    classes={outlinedInputClasses}
                  />
                </FormControl>
            </Grid>
         </Grid>
         ) : null
        )}

       {!imagePreviewUrl ? (
         <div>
           <Grid container spacing={2} justify="center">
             <Grid item>
               <input
               accept="image/*"
               className={classes.input}
               id="raised-button-file"
               type="file"
               style={{ display: 'none' }}
               onChange={selectFile}
               />
               <label htmlFor="raised-button-file">
                 <HinzuButton component="span" variant="contained" color="primary">
                   Select a Photo
                 </HinzuButton>
               </label>
             </Grid>
           </Grid>
         </div>
       ) : (
         <div>
           {$imagePreview}
         </div>
       )}

     </Container>
   </div>
</div>

  );
};

export default ImageUploadAction;
