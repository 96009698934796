import axios from "axios";

const DJANGO_API_URL = process.env.REACT_APP_BACKEND_API || "http://localhost:8015/nafthaline/api/react-web/"

export default axios.create({
  baseURL: DJANGO_API_URL,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true,
});
